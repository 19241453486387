import Logo from '../img/logo_sm.png'
import Img from '../img/img02.png'
import User from '../img/user.png'
import { Link } from "react-router-dom";
import { useState } from 'react';
function CommingBack() {
  const [show,Setshow]=useState(false)
  const ShowPopup=()=>{
    Setshow(true)
  }
  const hidePopup = () =>{
    Setshow(false)
  }
  return (
    <div className="main_wrapper">
        <div className="header_logo header_thankyou">
            <div className='header_logo_left'><img src={Logo} className="img_max"  alt='img' width="100%" height="100%"/></div>
            <div className='header_logo_right'><span>Hi,Buddy</span><Link to="/signup"><img src={User} className="img_max" alt='img' width="100%" height="100%"/></Link></div>
        </div>
        <div className='scanner_inner_wrap'>
        <div className='scanner_body thankyou_body'>
        <img src={Img} className="img_max" alt='img' width="100%" height="100%"/>
        </div>
       
        <div className={`scanner_connt ${show ? "hide" : ""}`}>
            <h1 className='main_heading'>Thank You For Coming Back !</h1>
            <p className='main_para'>Let’s Experience all the services without any hassle from your phone screen</p>
        </div>
        <div className={`scanner_btns  ${show ? "hide" : ""}`}>
        {/* <Link to='/thankyou'> <button type='button' className='yes_btn'>Yes</button></Link> */}
        <button type='button' className='yes_btn'  onClick={()=>ShowPopup()}>Yes</button>
        </div>
        <div  className={`scanner_popup ${show ? "show" : ""}`}>
        <h2 className='main_heading'>Choose Service</h2>
        <p className='main_para'>Choose Service You came for so you can keep track of services you avail day by day</p>
            <div className='scanner_form_inner_div'>
                <select className='scannter_select'>
                    <option>Choose Services From</option>
                    <option>New Accout</option>
                    <option>Loans</option>
                    <option>Insurance</option>
                </select>
            </div>
            <div className='scanner_form_inner_div'>
                <select className='scannter_select'>
                    <option>Choose niche service you are Searching</option>
                </select>
            </div>
            <Link to='/thankyou'> <button type='button' className='yes_btn'  onClick={()=>hidePopup()}>Confirm</button></Link>
        </div> 
        </div>
    </div>
  );
}
export default CommingBack;
