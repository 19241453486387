import Logo from '../img/logo_sm.png'
import Img from '../img/img03.png'
import User from '../img/user.png'
import { Link } from "react-router-dom";
function ThankYou() {
  return (
    <div className="main_wrapper">
        <div className="header_logo header_thankyou">
            <div className='header_logo_left'><img src={Logo} className="img_max" alt='img' width="100%" height="100%"/></div>
            <div className='header_logo_right'><span>Hi,Buddy</span><Link to="/signup"><img src={User} className="img_max" alt='img' width="100%" height="100%"/></Link></div>
        </div>
        <div className='scanner_inner_wrap'>
        <h1 className='thank_heading'>Thank You!</h1>
        <div className='scanner_body scanner_thankyou'>
        <img src={Img} className="img_max"  alt='img' width="100%" height="100%"/>
        </div>
        <div className='scanner_connt'>
            <p className='main_para'>Thank you for choosing us as your partner</p>
        </div>
        </div>
    </div>
  );
}
export default ThankYou;
