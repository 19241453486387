import Logo from '../img/logo.png'
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
function Form() {
    const [value, onChange] = useState('10:00');
  return (
    <div className="main_wrapper">
        <div className="header_logo">
            <img src={Logo} className="img_max" alt='img' width="100%" height="100%"/>
        </div>
        <div className='scanner_form_div'>
            <div className='scanner_form_inner_div'>
                <input type="text" className='scannter_select' placeholder='Name'/>
            </div>
            <div className='scanner_form_inner_div'>
                <input type="email" className='scannter_select' placeholder='Email'/>
            </div>
            <div className='scanner_form_inner_div'>
                <input type="text" className='scannter_select' placeholder='Mobile Number'/>
            </div>
            <div className='scanner_form_inner_div'>
                <input type="text" className='scannter_select' placeholder='Location'/>
            </div>
            <div className='scanner_form_inner_div'>
                {/* <input type="text" className='scannter_select' placeholder='Time'/> */}
                <TimePicker onChange={onChange} value={value} />
            </div>
            <div className='scanner_form_inner_div'>
            <Link to='/'><button type='button' className='yes_btn'>Submit</button></Link>
            </div>
        </div>
    </div>
  );
}
export default Form;
