import Logo from '../img/logo.png'
import { Link } from "react-router-dom";
function GetOTP() {
  return (
    <div className="main_wrapper">
        <div className="header_logo">
            <img src={Logo} className="img_max"  alt='img' width="100%" height="100%"/>
        </div>
        <div className='scanner_sign_up'>
        <div className='scanner_connt'>
            <h1 className='main_heading'>OTP</h1>
            <p className='main_para'>Sign In or signup to have a better in-app experience</p>
        </div>
        <div className='get_otp'>
        <div className='get_otp_div'>
            <input type="text" className='otp_input' value="1"/>
        </div>
        <div className='get_otp_div'>
        <input type="text" className='otp_input' value="2"/>
        </div>
        <div className='get_otp_div'>
        <input type="text" className='otp_input' value="3"/>
        </div>
        <div className='get_otp_div'>
        <input type="text" className='otp_input' value="4"/>
        </div>
        </div>
        <div className='scanner_btns get_otp_btn'>
        <Link to='/form'><button type='button' className='yes_btn'>Get OTP</button></Link>            
        </div>
        </div>
    </div>
  );
}
export default GetOTP;
