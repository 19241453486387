import Logo from '../img/logo.png'
import Img from '../img/img04.png'
import { Link } from "react-router-dom";
function Customer() {
  return (
    <div className="main_wrapper">
        <div className="header_logo">
            <img src={Logo} className="img_max"  alt='img' width="100%" height="100%"/>
        </div>
        <div className='scanner_inner_wrap'>
        <div className='scanner_body scanner_customer'>
        <img src={Img} className="img_max"  alt='img' width="100%" height="100%"/>
        </div>
        <div className='scanner_connt'>
            <h1 className='main_heading'>An Existing Customer ?</h1>
        </div>
        <div className='scanner_btns'>
        <Link to='/commingback'> <button type='button' className='yes_btn'>Yes</button></Link>
        <Link to='/commingback'><button type='button' className='no_btn'>No</button></Link>
        </div>
        </div>
    </div>
  );
}
export default Customer;
