import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./css/style.css";
import "./css/responsive.css";
import Home from "./pages/home";
import Customer from "./pages/customer";
import CommingBack from "./pages/commingback";
import ThankYou from "./pages/thankyou";
import SignUp from "./pages/signup";
import GetOTP from "./pages/getotp";
import Form from "./pages/form";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
    <Route path="/" element={<Home />}/>
    <Route path="/customer" element={<Customer />}/>
    <Route path="/commingback" element={<CommingBack />}/>
    <Route path="/thankyou" element={<ThankYou />}/>
    <Route path="/signup" element={<SignUp />}/>
    <Route path="/getotp" element={<GetOTP />}/>
    <Route path="/form" element={<Form />}/>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
